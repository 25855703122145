export const columns = [
  {
    title: '成片',
    scopedSlots: { customRender: 'cover' },
  },
  {
    title: '脚本名称',
    scopedSlots: { customRender: 'scriptName' },
  },
  {
    title: '操作',
    scopedSlots: { customRender: 'operate' },
  },
  {
    title: '任务名称',
    dataIndex: 'taskName',
  },
  {
    title: '品牌',
    dataIndex: 'principalName',
  },
  {
    title: '产品',
    dataIndex: 'productName',
  },
  {
    title: '视频类型',
    dataIndex: 'videoCategoryName',
  },
  {
    title: '经销商名称',
    dataIndex: 'dealerName',
  },
  {
    title: '快手号昵称',
    dataIndex: 'authorName',
  },
  {
    title: '发布快手',
    dataIndex: 'publicStatus',
    scopedSlots: { customRender: 'publicStatus' },
  },
  {
    title: '创建时间',
    dataIndex: 'ctime',
  },
];

export const ReleaseListColumns = [
  {
    title: '发布操作时间',
    dataIndex: 'ctime',
  },
  {
    title: '操作人',
    dataIndex: 'userName',
  },
  {
    title: '成片',
    scopedSlots: { customRender: 'coverUrl' },
  },
  {
    title: '脚本名称',
    scopedSlots: { customRender: 'scriptName' },
  },

  {
    title: '发布平台',
    dataIndex: 'origin',
    customRender(text) {
      return text == 'DOUYIN' ? '抖音' : text == 'KUAISHOU' ? '快手' : '-';
    },
  },
  {
    title: '发布账号昵称',
    dataIndex: 'authorName',
  },

  {
    title: '发布状态',
    dataIndex: 'status',
    scopedSlots: { customRender: 'status' },
  },
  {
    title: '发布失败原因',
    dataIndex: 'failCause',
  },
  {
    title: '计划发布时间',
    dataIndex: 'publishTime',
  },
  {
    title: '操作',
    scopedSlots: { customRender: 'operate' },
  },
];
