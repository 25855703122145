<template>
  <div class="prompt-debug-list">
    <a-form-model ref="form" :model="form" v-bind="layout">
      <a-row :gutter="12">
        <a-col :span="5">
          <a-form-model-item label="经销商名称">
            <a-input v-model="form.dealerName" placeholder="请输入" allowClear />
          </a-form-model-item>
        </a-col>
        <a-col :span="5">
          <a-form-model-item label="经销商编码">
            <a-input v-model="form.dealerCode" placeholder="请输入" allowClear />
          </a-form-model-item>
        </a-col>
        <a-col :span="6">
          <a-form-model-item label="品牌（主体）">
            <a-select
              v-model="form.principalId"
              show-search
              option-filter-prop="children"
              allow-clear
              placeholder="请选择"
            >
              <a-select-option v-for="item in principalList" :key="item.id">{{ item.name }}</a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :span="6">
          <a-form-model-item label="产品">
            <a-input v-model="form.productName" placeholder="请输入" allowClear />
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row :gutter="12">
        <a-col :span="5">
          <a-form-model-item label="快手号昵称">
            <a-input v-model="form.authorName" placeholder="请输入" allowClear />
          </a-form-model-item>
        </a-col>
        <a-col :span="5">
          <a-form-model-item label="脚本名称">
            <a-input v-model="form.criptName" placeholder="请输入" allowClear />
          </a-form-model-item>
        </a-col>
        <a-col :span="6">
          <a-form-model-item label="任务名称">
            <a-input v-model="form.taskName" placeholder="请输入" allowClear />
          </a-form-model-item>
        </a-col>
        <a-col :span="6">
          <a-form-model-item label="视频类型">
            <a-select v-model="form.videoType" placeholder="请选择" allowClear>
              <a-select-option v-for="item in videoTypeList" :key="item.code" :value="item.code">
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row :gutter="12">
        <a-col :span="5">
          <a-form-model-item label="发布状态">
            <a-select v-model="form.publicStatus" placeholder="请选择" allowClear>
              <a-select-option v-for="item in dyStatusList" :key="item.code" :value="item.code">
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :span="10">
          <a-form-model-item label="创建日期">
            <a-date-picker style="width: 48%" v-model="form.startTime" placeholder="开始时间" />
            ~
            <a-date-picker style="width: 48%" v-model="form.endTime" placeholder="结束时间" />
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="1">
        </a-col>
        <a-col :span="18">
          <a-space>
            <a-dropdown>
              <a-button>批量下载</a-button>
              <a-menu slot="overlay">
                <a-menu-item>
                  <a @click="handleDownload(true)">打包下载</a>
                </a-menu-item>
                <a-menu-item>
                  <a @click="handleDownload(false)">不打包下载</a>
                </a-menu-item>
              </a-menu>
            </a-dropdown>
            <a-button @click="handleCopy">批量获取脚本名称和链接</a-button>
            <a-button @click="handleRollback">批量退回</a-button>
          </a-space>
        </a-col>
        <a-col :span="4">
          <a-space>
            <a-button @click="handleReset">重置</a-button>
            <a-button type="primary" @click="getTableData(true)">查询</a-button>
          </a-space>
        </a-col>
      </a-row>
    </a-form-model>
    <div class="data-table">
      <a-table
        :loading="loading"
        :columns="columns"
        :data-source="tableData"
        :pagination="false"
        row-key="id"
        :row-selection="rowSelection"
      >
        <div slot="operate" slot-scope="record">
          <a-button type="link" @click="showDouyinModal(record)">发布快手</a-button>
        </div>
        <div slot="publicStatus" slot-scope="text, record">
          <span>{{ text || '-' }}</span>
          <a-icon
            v-if="record.publishId != null && record.publishId"
            type="redo"
            style="color: #40a9ff; padding-left: 6px; cursor: pointer"
            @click="refreshStatus(record)"
          />
        </div>
        <div class="cover-column" slot="cover" slot-scope="text, record" @click="handlePreview(record)">
          <base-img :src="record.coverUrl" alt="图片" referrerpolicy="no-referrer" style="cursor: pointer" />
        </div>
        <div slot="scriptName" slot-scope="text, record" class="blue-text" @click="handlePreview(record)">
          {{ record.criptName }}
        </div>
      </a-table>
      <base-pagination
        :current-page="pagination.current"
        :page-size="pagination.pageSize"
        :total="pagination.total"
        :options="['100']"
        @onChange="handlePaginationChange"
        @onShowSizeChange="handlePaginationChange"
      />
    </div>

    <PreviewModal
      :visible="previewVisible"
      :previewSrc="previewSrc"
      :isAuth="false"
      :isAutoplay="true"
      previewType="video"
      @cancel="
        previewVisible = false;
        previewSrc = '';
      "
    />

    <!-- 发布快手-->
    <a-modal title="快手视频发布" :visible="DouyinModal" :closable="false" width="700px" :key="formData.awemeUrl">
      <a-form-model ref="formData" :model="formData" :rules="rules" layout="inline">
        <a-row>
          <a-col>
            <a-form-model-item label="脚本名称">
              <span>{{ formData.criptName || '-' }}</span>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="8">
            <a-form-model-item label="经销商名称">
              <span>{{ formData.dealerName || '-' }}</span>
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item label="品牌">
              <span>{{ formData.principalName || '-' }}</span>
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item label="产品">
              <span>{{ formData.product_name_text || '-' }}</span>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col>
            <a-form-model-item label="选择快手号" prop="author_id" style="padding-bottom: 12px">
              <a-select
                v-model="formData.author_id"
                option-filter-prop="children"
                show-search
                style="width: 300px"
                @change="getAuthorItem"
              >
                <a-select-option v-for="item of authorIdList" :key="item.authorId" :vlue="item.authorId">
                  {{ item.nickname }}
                  <span style="padding-left: 4px; color: #ccc">
                    （{{ item.openStatus == 1 ? '权限满足' : item.openStatus == 0 ? '权限不满足' : '' }}）
                  </span>
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col>
            <div class="col-box">
              <div class="col_left">
                <a-form-model-item label="选择封面" extra="" prop="cover_path_list">
                  <div style="display: flex">
                    <v-upload
                      :accept-list="'.png,.jpg,.jpeg'"
                      :is-open-preview="false"
                      :upload-title="'上传封面图'"
                      :has-file-list="formData.cover_path_list"
                      style="width: 42%"
                      randomFileName
                      @handleUrl="handleUrl"
                      @handlePreview="handlePreviewOpen(arguments)"
                    />
                    <span style="color: #cccccc">只支持 .png 和 .jpg 格式</span>
                  </div>
                </a-form-model-item>
                <a-form-model-item label="标题" prop="text">
                  <a-textarea placeholder="请输入" v-model.trim="formData.text" style="width: 400px" :rows="6" />
                  <p style="padding-top: 4px">1、没有字数限制</p>
                  <p>2、支持在标题中加#话题</p>
                  <p>3、#之前需要加空格才能生效</p>
                </a-form-model-item>
              </div>
              <div class="col_right">
                <EasyPlayer
                  :videoUrl="formData.awemeUrl || ''"
                  @message="$message"
                  fluent
                  aspect="9:14"
                  stretch
                  style="width: 100%"
                  :auto-play="false"
                  :live="false"
                  :speed="false"
                  :show-custom-button="false"
                  ref="EasyPlayerRef"
                />
                <AButton
                  type="primary"
                  @click="onScreenshot"
                  style="margin-left: 25%; margin-top: 12px; padding: 3px 20px; font-size: 14px"
                >截图封面
                </AButton>
              </div>
            </div>
          </a-col>
        </a-row>
        <a-row>
          <a-col>
            <a-form-model-item label="发布时间" prop="publish_time_type">
              <div class="=picker-box">
                <a-radio-group v-model="formData.publish_time_type" @change="formData.publish_time = ''">
                  <a-radio :value="1"> 马上发布</a-radio>
                  <a-radio :value="2"> 定时发布</a-radio>
                </a-radio-group>
                <a-date-picker
                  :disabled="formData.publish_time_type == 1"
                  v-model="formData.publish_time"
                  placeholder="请选择时间"
                  :show-time="{ format: 'HH:mm:ss' }"
                  format="YYYY-MM-DD HH:mm:ss"
                  value-format="YYYY-MM-DD HH:mm:ss"
                  :disabled-date="disabledDate"
                  :disabled-time="disabledDateTime"
                  :showToday="false"
                />
              </div>
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
      <template slot="footer">
        <a-row type="flex" justify="center">
          <a-col :span="1"></a-col>
          <a-col>
            <a-button type="primary" :loading="is_submit" @click="handleDouyinModalOk" style="padding: 2px 24px"
            >提交
            </a-button>
          </a-col>
          <a-col :span="1"></a-col>
          <a-col>
            <a-button @click="handleDouyinModalCancel" style="padding: 4px 26px">取消</a-button>
          </a-col>
          <a-col :span="1"></a-col>
        </a-row>
      </template>
    </a-modal>

    <preview-modal :visible="preview_visible" :preview-src="preview_src" @cancel="handlePreviewCancel" />
  </div>
</template>

<script>
import api from '@/api/autoVideoLib.js';
import BaseImg from '@/components/BaseImg';
import moment from 'moment';
import PreviewModal from '@/components/PreviewModalVideo/PreviewModalVideo';
import { columns } from './constants';
import record from '../../workOrder/record.vue';
import EasyPlayer from '@easydarwin/easyplayer';
import html2canvas from 'html2canvas';
import utils from '@/common/util.js';
import _base_https from '@/api/base_api';
import dayjs from 'dayjs';

export default {
  components: {
    BaseImg,
    PreviewModal,
    EasyPlayer,
  },
  data() {
    return {
      columns,
      loading: false,
      layout: {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
      },
      principalList: [],
      dyStatusList: [],
      videoTypeList: [],
      form: {
        authorId: undefined,
        authorName: undefined,
        criptName: undefined,
        dealerId: undefined,
        dealerCode: undefined,
        dealerName: undefined,
        endTime: undefined,
        page: undefined,
        principalId: undefined,
        productId: undefined,
        productName: undefined,
        publicStatus: undefined,
        size: undefined,
        startTime: undefined,
        taskName: undefined,
        videoType: undefined,
      },
      selectedRowKeys: [],
      tableData: [],
      pagination: {
        total: 0,
        current: 1,
        pageSize: 100,
      },
      previewVisible: false,
      previewSrc: '',
      data: {},
      formData: {
        cover_path_list: [],
        product_name_text: '',
        awemeUrl: '',
        criptName: '',
        dealerName: '',
        principalName: '',
        author_id: '', //主播id 选择的抖音号
        author_nickname: '', //主播昵称 选择的抖音号name
        avatar: '', //头像
        business_type: '1',
        cover_path: '', //选取封面
        cover_tsp: '0', //将传入的指定时间点对应帧设置为视频封面（单位：秒） 不截图默认传0
        dealer_id: '',
        file_name: '云端合成', //传成片url文件名 (如 云端合成.mp4)
        foreignKey: '', //母版id(详情取souceMasterboardId)
        open_id: '', //(抖音下拉数据取openId)
        product: {
          cover: '', //选取封面
          draft_project_id: '', //详情接口取draftProjectId
          form: 0,
          id: '', //详情取id
          principalId: '', //详情取principalId
          product_name: '云端合成',
          product_type: 3,
          sort: 0,
          product_url: '', //详情取awemeUrl
          source: 'bfz',
          storyboard_count: 0,
          foreign_key: '',
          user_id: window.localStorage.getItem('user_id'), //登陆的账号
          user_name: window.localStorage.getItem('user_name'), //登陆的昵称
        },
        productName: '云端合成',
        productType: '3',
        productUrl: '', //成片地址
        publishSource: 4,
        publish_time: '', //发布时间 定时发布需要传
        publish_type: 2, //发布类型 1：抖音 2：快手 目前只有抖音
        text: '', //标题
        publish_time_type: 1,
      },
      rules: {
        author_id: [{ required: true, message: '请选择快手号', trigger: 'change' }],
        cover_path_list: [{ type: 'array', required: true, message: '请选择封面', trigger: 'change' }],
        publish_time_type: [{ required: true, message: '请选择发布时间', trigger: 'change' }],
        text: [{ required: true, message: '请输入标题', trigger: 'blur' }],
      },
      DouyinModal: false,
      is_submit: false,
      authorIdList: [],
      thumb_url:
        'http://afanticar-test.oss-cn-hangzhou.aliyuncs.com/adsys/2020/10/xQVHkI8uIrzoICsANthwzqqhZtAUUUmIqUr0uJiX.jpeg',
      preview_visible: false,
      preview_src: '',
      upload_file_params: {
        business_code: 'bfz', //所属业务组编码(风火轮：fhl  聚金塔：jjt 百夫长：bfz 数据组-微控 vkong-new)
        need_compress: 1, //是否需要压缩处理-针对图片(1:是  2:否  默认是)
        type: 1, //文件类型(1:图片文件,2:音频文件,3:视频文件,4:文本文件,5:文档文件,6:emoji,7:其他)
      },
      cover_list: ['png', 'jpg', 'jpeg'],
      is_cover_tsp: 0,
    };
  },
  computed: {
    record() {
      return record;
    },
    rowSelection() {
      const { selectedRowKeys } = this;
      return {
        selectedRowKeys,
        onChange: (selectedRowKeys) => {
          this.selectedRowKeys = selectedRowKeys;
        },
      };
    },
  },
  mounted() {
    if (this.$route.query.dealerName) {
      this.form.dealerName = this.$route.query.dealerName;
      this.form.startTime = undefined;
      this.form.endTime = undefined;
    } else {
      this.form.startTime = moment(new Date()).subtract(29, 'days');
      this.form.endTime = moment(new Date());
    }
    this.getDyPublishStatusList();
    this.getVideoTypeList();
    this.getPrincipalList('');
    this.getTableData(true);
  },
  methods: {
    getTableData(isFirst) {
      this.selectedRowKeys = [];
      const startTime = this.form.startTime ? moment(this.form.startTime).valueOf() : undefined;
      const endTime = this.form.endTime ? moment(this.form.endTime).valueOf() : undefined;
      if (startTime && endTime && startTime > endTime) {
        this.$message.error('发布操作开始时间不能大于结束时间');
        return;
      }
      this.loading = true;
      const params = {
        ...this.form,
        startTime: this.form.startTime ? moment(this.form.startTime).format('YYYY-MM-DD 00:00:00') : undefined,
        endTime: this.form.endTime ? moment(this.form.endTime).format('YYYY-MM-DD 23:59:59') : undefined,
        page: isFirst ? 1 : this.pagination.current,
        size: this.pagination.pageSize,
      };
      api
        .getVideoList(params)
        .then(({ data, code, message }) => {
          if (code === 200) {
            this.tableData = data?.list;
            this.pagination.current = data?.page;
            this.pagination.total = data?.total;
          } else {
            this.$message.error(`${ message }`);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    handlePaginationChange(current, pageSize) {
      this.pagination.current = current;
      this.pagination.pageSize = pageSize;
      this.getTableData();
    },
    handleReset() {
      Object.assign(this.form, this.$options.data().form);
      this.form.startTime = moment(new Date()).subtract(29, 'days');
      this.form.endTime = moment(new Date());
      this.getTableData(true);
    },
    /**
     * 获取品牌列表
     */
    async getPrincipalList(val) {
      try {
        const { code, data, message } = await api.getPrincipalList({ name: val });
        if (code === 200) {
          this.principalList = data;
        } else {
          return this.$message.error(`${ message }`);
        }
      } catch (error) {
        this.$message.error('操作失败');
      }
    },
    /**
     * 获取抖音发布状态列表
     */
    async getDyPublishStatusList() {
      try {
        const { code, data, message } = await api.getDyPublishStatusList();
        if (code === 200) {
          this.dyStatusList = data;
        } else {
          this.message.error(message);
        }
      } catch (error) {
      }
    },
    /**
     * 获取视频类型列表
     */
    async getVideoTypeList() {
      try {
        const { code, data, message } = await api.getVideoTypeList();
        if (code === 200) {
          this.videoTypeList = data;
        } else {
          this.message.error(message);
        }
      } catch (error) {
      }
    },
    async report(actionType) {
      const params = {
        actionType,
        awemeIds: this.selectedRowKeys,
        dealerId: this.form.dealerId,
        platform: 'bfz',
        userId: window.localStorage.getItem('user_id'),
      };
      try {
        await api.report(params);
      } catch (error) {
        console.log('error:', error);
      }
    },
    async handleDownload(isZip) {
      if (this.selectedRowKeys.length === 0) {
        this.$message.warning('请至少选择一条内容');
        return;
      }
      this.report(1);
      const downloadList = this.tableData
        .filter((item) => this.selectedRowKeys.includes(item.id))
        .map((item) => ({
          name: item.criptName,
          url: item.awemeUrl,
        }));
      localStorage.downloadList = JSON.stringify(downloadList);
      const toUrl = this.$router.resolve({ name: 'downloadTask', query: { isZip } });
      window.open(toUrl.href, '_blank');
    },
    handleCopy() {
      if (this.selectedRowKeys.length === 0) {
        this.$message.warning('请至少选择一条内容');
        return;
      }
      const copyList = this.tableData
        .filter((item) => {
          return this.selectedRowKeys.includes(item.id);
        })
        .map((item) => {
          return `${ item.criptName } ${ item.awemeUrl }`;
        });
      this.$copyText(copyList.join('\n')).then(
        () => {
          this.$message.success('复制成功');
          this.report(2);
        },
        () => {
          this.$message.error('复制失败');
        },
      );
    },
    handlePreview(record) {
      this.previewSrc = record.awemeUrl;
      this.previewVisible = true;
    },
    showDouyinModal(record) {
      this.loading = true;
      api.getPublishDetail({ id: record.id }).then((res) => {
        if (res.code == 200) {
          let data = res.data || {};
          Object.assign(this.formData, {
            criptName: data.criptName,
            awemeUrl: data.awemeUrl,
            // file_name: data.awemeUrl,
            dealer_id: data.dealerId,
            dealerName: data.dealerName,
            principalName: data.principalName,
            product_name_text: data.productName,
            author_id: data.authorId,
            author_nickname: data.authorName,
            foreignKey: data.souceMasterboardId || '-3',
            productUrl: data.awemeUrl,
            cover_path_list: this.hasFileArr(data.coverUrl),
          });
          this.$set(this.formData.product, 'draft_project_id', data.draftProjectId);
          this.$set(this.formData.product, 'cover', data.coverUrl);
          this.$set(this.formData.product, 'id', data.id);
          this.$set(this.formData.product, 'principalId', data.principalId);
          this.$set(this.formData.product, 'product_url', data.awemeUrl);
          this.$set(this.formData.product, 'foreign_key', data.souceMasterboardId || '-3');
          this.DouyinModal = true;
          this.$nextTick(() => {
            this.$refs.EasyPlayerRef.$el.querySelector('video').setAttribute('crossOrigin', 'anonymous');
            document.title = '自动合成视频库 - 百夫长';
          });
          this.getPublishAuthorList(data.dealerId, data.authorId);
        }
      });
    },
    hasFileArr(url) {
      return [
        {
          uid: -1,
          name: 'image/png',
          status: 'done',
          url: url,
          thumbUrl: url || this.thumb_url,
          editor: true,
        },
      ];
    },
    //预览
    handlePreviewOpen(data) {
      this.preview_visible = true;
      this.preview_src = data[0];
    },
    //关闭预览
    handlePreviewCancel() {
      this.preview_src = '';
      this.preview_visible = false;
    },
    handleUrl(data) {
      let url = data.response?.url;
      this.$set(this.formData, 'cover_path_list', this.hasFileArr(url));
    },
    /* //日期选择框
    onChangeDate(dates) {
      console.log(dates);
      let start_time = '';
      let end_time = '';
      if (dates) {
        console.log(dates);
        start_time = this.$moment(dates[0]._d).format('YYYY-MM-DD HH:mm:ss');
        end_time = this.$moment(dates[1]._d).format('YYYY-MM-DD HH:mm:ss');
      }
    }, */
    handleDouyinModalOk() {
      this.$refs.formData.validate((valid) => {
        if (valid) {
          if (this.formData.publish_time_type == 2 && !this.formData.publish_time) {
            // this.$refs.publish_time_type.onFieldChange();
            this.$message.error('请选择定时发布时间');
            return;
          }
          let _data = this.formData;
          _data.cover_path = this.formData.cover_path_list[0].url || '';
          _data.product.cover = this.formData.cover_path_list[0].url || '';
          this.is_submit = true;
          if (this.is_cover_tsp) {
            _data.cover_tsp = this.formData.cover_tsp;
          } else {
            _data.cover_tsp = '0';
          }
          api.refreshVideo(_data).then((res) => {
            if (res.code == 200) {
              this.$message.success(res.message || '视频发布成功');
              this.handleDouyinModalCancel();
              this.getTableData(true);
            } else {
              this.$message.error(res.message || '视频发布失败');
            }
            this.is_submit = false;
          });
        }
      });
    },
    handleDouyinModalCancel() {
      this.$refs.formData.resetFields();
      this.DouyinModal = false;
      this.formData = {
        cover_path_list: [],
        product_name_text: '',
        awemeUrl: '',
        criptName: '',
        dealerName: '',
        principalName: '',
        author_id: '', //主播id 选择的抖音号
        author_nickname: '', //主播昵称 选择的抖音号name
        avatar: '', //头像
        business_type: '1',
        cover_path: '', //选取封面
        cover_tsp: '0', //将传入的指定时间点对应帧设置为视频封面（单位：秒）
        dealer_id: '',
        file_name: '云端合成', //传成片url文件名 (如 云端合成.mp4)
        foreignKey: '', //母版id(详情取souceMasterboardId)
        open_id: '', //(抖音下拉数据取openId)
        product: {
          cover: '', //选取封面
          draft_project_id: '', //详情接口取draftProjectId
          form: 0,
          id: '', //详情取id
          principalId: '', //详情取principalId
          product_name: '云端合成',
          product_type: 3,
          sort: 0,
          product_url: '', //详情取awemeUrl
          source: 'bfz',
          storyboard_count: 0,
          foreign_key: '',
          user_id: window.localStorage.getItem('user_id'), //登陆的账号
          user_name: window.localStorage.getItem('user_name'), //登陆的昵称
        },
        productName: '云端合成',
        productType: '3',
        productUrl: '', //成片地址
        publishSource: 4,
        publish_time: '', //发布时间 定时发布需要传
        publish_type: 2, //发布类型 1：抖音 2：快手 目前只有抖音
        text: '', //标题
        publish_time_type: 1,
      };
    },
    onScreenshot() {
      const vm = this.$refs.EasyPlayerRef.$el;
      if (vm) {
        const video = vm.querySelector('video');
        html2canvas(video, { useCORS: true, allowTaint: true }).then((res) => {
          const imgBase64 = res.toDataURL('image/png', 1); // toDataURL将canvas转化为图片
          const fileOfBlob = this.dataURLtoFile(imgBase64, 'image/png');
          const formData = new FormData();
          formData.append('file', fileOfBlob);
          formData.append('business_code', this.upload_file_params.business_code);
          formData.append('need_compress', this.upload_file_params.need_compress);
          formData.append('type', this.upload_file_params.type);
          const videoTime = this.$refs.EasyPlayerRef.getCurrentTime(); //获取截图的时间 秒
          this.formData.cover_tsp = videoTime;
          this.is_cover_tsp = 1;
          this.normalUploadFile(formData);
        });
      }
    },
    dataURLtoFile(dataURI, type) {
      let binary = atob(dataURI.split(',')[1]);
      let array = [];
      for (let i = 0; i < binary.length; i++) {
        array.push(binary.charCodeAt(i));
      }
      return new File([new Blob([new Uint8Array(array)], { type: type })], `${ +new Date() }.png`);
    },
    getPublishAuthorList(dealerId, authorId) {
      api.getPublishAuthorList({ dealerId }).then((res) => {
        if (res.code == 200) {
          this.authorIdList = res.data || [];
          const item = this.authorIdList.find((v) => v.authorId == authorId);
          this.$set(this.formData, 'open_id', item ? item.openId : '');
        } else {
          this.$message.error('获取数据失败');
        }
      });
      this.loading = false;
    },
    refreshStatus(record) {
      this.loading = true;
      api.refreshStatus({ publishId: record.publishId }).then((res) => {
        if (res.code == 200) {
          this.$message.success('已获取最新数据');
          this.$set(record, 'publicStatus', res.data.status);
          // this.getTableData(true);
        } else {
          this.$message.error(res.data.message || '获取数据失败');
        }
        this.loading = false;
      });
    },
    async normalUploadFile(formData) {
      /*    let data = {
           params: {
             business_code: 'fhl',
             type: 1,
           },
           body: {
             file,
           },
         }; */
      try {
        _base_https.uploadFile(formData).then((res) => {
          if (res.code === 200) {
            const cover_path = res.data.url || '';
            this.$set(this.formData, 'cover_path_list', this.hasFileArr(cover_path));
          } else {
            this.$message.error('请重新截图上传');
          }
        });
      } catch (err) {
        console.error(err);
        this.$message.error(`上传失败-请重新截图上传`);
      }
    },
    getAuthorItem() {
      const item = this.authorIdList.find((v) => v.authorId === this.formData.author_id);
      this.formData.author_id = item.authorId;
      this.formData.author_nickname = item.nickname;
      this.formData.open_id = item.openId;
    },
    /*

    // 禁用小于等于当前系统时间
    disabledDate(current) {
      return current && current < moment().startOf('day');
    },
    disabledDateTime(_, type) {
      console.log(_, type);
      const currentDate = new Date();
      const disabledHours = Array.from({ length: 24 }, (_, i) => i).slice(0, currentDate.getHours());
      const disabledMinutes = Array.from({ length: 60 }, (_, i) => i).slice(0, currentDate.getMinutes() + 1);
      // const disabledSeconds = Array.from({ length: 60 }, (_, i) => i).slice(0, currentDate.getSeconds());

      return {
        disabledHours: () => disabledHours,
        disabledMinutes: () => disabledMinutes,
        // disabledSeconds: () => disabledSeconds,
      };
    },


    */

    // 禁用小于等于当前系统时间
    disabledDate(current) {
      return current && current < moment().startOf('day');
    },
    disabledDateTime(current) {
      const currentDate = moment(new Date()).add(1, 'minute');
      const disabledHours = Array.from({ length: 24 }, (_, i) =>
        moment(current).hour(i).format('YYYY-MM-DD HH:mm:ss'),
      ).flatMap((item) => {
        const h = moment(item);
        return h.isBefore(currentDate, 'h') ? [h.hour()] : [];
      });
      const disabledMinutes = Array.from({ length: 60 }, (_, i) =>
        moment(current).minute(i).format('YYYY-MM-DD HH:mm:ss'),
      ).flatMap((T) => {
        const h = moment(T);
        return h.isBefore(currentDate, 'minute') ? [h.minute()] : [];
      });
      // const disabledSeconds = Array.from({ length: 60 }, (_, i) => i).slice(0, currentDate.getSeconds());

      return {
        disabledHours: () => disabledHours,
        disabledMinutes: () => disabledMinutes,
        // disabledSeconds: () => disabledSeconds,
      };
    },
    changeDateTime(_date) {
      if (_date) {
        const currentDate = dayjs().format('YYYY-MM-DD');
        const inputDate = dayjs(_date).format('YYYY-MM-DD');

        // 检查日期是否相同
        const isToday = currentDate === inputDate;
        if (isToday) {
          // 增加1分钟
          this.formData.publish_time = dayjs().add(1, 'minute').format('YYYY-MM-DD HH:mm:ss');
        } else {
          const yearMonthDay = dayjs(_date).format('YYYY-MM-DD');
          this.formData.publish_time = yearMonthDay + '00:00:00';
        }
      }
    },
    handleRollback() {
      if (this.selectedRowKeys.length === 0) {
        this.$message.warning('请至少选择一条内容');
        return;
      }
      let that = this;
      this.$confirm({
        title: '确定要退回选中的视频吗',
        okText: '确定',
        cancelText: '取消',
        onOk: () => {
          that.loading = true;
          api.rollback(that.selectedRowKeys).then((res) => {
            if (res.code == 200) {
              that.$message.success('退回成功');
              that.getTableData(true);
            } else {
              that.$message.error(res.message || '退回失败');
            }
            that.loading = false;
          });
        },
        onCancel() {
        },
      });
    },
  },
};
</script>

<style lang="less">
.data-table {
  margin-top: 20px;

  .cover-column {
    width: 60px;
    height: 80px;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .blue-text {
    color: #509bf8;
    text-decoration: underline;
    cursor: pointer;
  }
}

.col-box {
  display: flex;
  //align-items: center;

  .col_left {
    p {
      line-height: 7px;
      color: #cccccc;
    }
  }

  .col_right {
    width: 60%;
    //height: 340px;
    //border: 1px solid #9d9c9c;
  }
}
</style>
